.icon-class {
    color: #ffdc97 !important;
}
.tooltip-inner {
    max-width: 350px !important;
    padding: 0.25rem 0.5rem !important;
    color: #000 !important;
    text-align: left !important;
    background-color: #fff !important;
    border-radius: 0 !important;
    border: 3px solid #999999;
    p {
        font-size: 16px;
        font-family: Helvetica, Arial, sans-serif;
        white-space: pre-wrap;
    }
}
.bs-tooltip-right .arrow::before {
    border-right-color: #999999 !important;
}
.tooltip.show {
    opacity: 1 !important;
}
