.sg-card-lt {
    display: block;
    font-size: 16px;
    font-weight: bold;
}

.dtail {
    font-size: 17px;
    display: block;
    font-weight: 500;
}

.dtail-bold {
    font-size: 19px;
    font-weight: bold;
    display: block;
}

.color-line {
    position: absolute;
    height: -webkit-fill-available;
    width: 6px;
    left: 0;
}

.head-div {
    color: #fff;
}

.sg-card-lt {
    display: block;
    font-size: 16px;
    font-weight: bold;
}

.sg-card-rt {
    display: block;
    font-size: 16px;
    font-weight: bold;
}
.sg {
    display: block;
    font-size: 14px;
}
.bg-work-ticket {
    background-color: blue !important;
}
.ttl {
    font-weight: 600;
}
.content-section {
    padding-bottom: 66px;
}
