#root {
    // Scss variables which we gonna assign using
    // useState and JavaScript in reactJS
    $background-color: #fff;
    $background-color-active: #fff;
    $text-color: #262833;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-template-rows: auto;
}

.booking-model {
    .modal-dialog {
        max-width: 60%;
        width: 60%;
    }
}

.background-box-color .card .card-body .card-header-tabs-box a {
    background-color: var(--background-color) !important;
}

.background-box-color .card .card-body .card-header-tabs-box a.active {
    color: var(--background-color-active) !important;
}

.payment-form-main {
    padding: 10px 0px;
    height: 100%;
    .payment-form-headding {
        font-size: 18px;
        line-height: 30px;
        font-weight: 700;
        margin: 0px 0;
        color: #fff;

        @media (max-width: 1200px) {
            font-size: 16px;
            font-weight: 500;
        }
    }
    .payment-form-desc-main {
        padding: 15px 30px;

        @media (max-width: 1024px) {
            padding: 10px 15px;
        }

        border-radius: 10px;
        height: 100%;
        .title-text {
            width: 100%;
            font-size: 17px;
            line-height: 30px;
            font-weight: 700;
            margin: 0px 0;
            color: #fff;
            @media (min-width: 1199px) and (max-width: 1599px) {
                width: 125px;
            }
        }

        .booking-details {
            .booking-qty {
                font-size: 18px;
                font-weight: 600;
                color: #fff;
                display: flex;
                @media (max-width: 1024px) {
                    font-weight: 500;
                    font-size: 16px;
                }

                .list-content {
                    padding-left: 5px;
                    font-size: 18px;
                    color: #fff;
                    font-weight: 600;
                    @media (max-width: 1024px) {
                        font-weight: 500;
                        font-size: 16px;
                    }
                }
            }

            .vehicle-box-info {
                display: flex;
                justify-content: flex-start;
                color: #fff;

                .booking-qty {
                    flex-direction: column;
                    display: flex;
                }
            }
        }

        .info-wrapper {
            padding-top: 25px;
            .title-info {
                color: #fff;
                font-size: 18px;
                font-weight: 600;
            }
            .total-text p {
                color: #fff;
                font-size: 18px;
                font-weight: 600;
                display: inline-block;
                margin-left: 10px;
                @media (max-width: 567px) {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }

        background-color: #383636;
    }
}
.total-text-left p {
    margin-left: 0px !important;
}

.info-wrapper-2 {
    padding-top: 5px !important;
}
.bokking-details-main {
    .booking-details-headding {
        font-size: 28px;
        line-height: 30px;
        font-weight: 600;
        margin: 20px 0;
        color: #fff;
    }
    .booking-details-wrapper {
        border-radius: 10px;
        padding: 25px 20px;
        background-color: #261e1e;
        .booking-wrap {
            border-bottom: 1px solid #fff;
            margin-bottom: 15px;
            .booking-details {
                margin-bottom: 15px;

                .booking-qty {
                    font-size: 18px;
                    line-height: 26px;
                    font-weight: 600;
                    color: rgb(122, 117, 117);
                    margin-bottom: 0;
                }
            }
        }
    }
}

.top-header {
    .top-header-box {
        padding: 0px 20px;
        background-color: #000;
        .booking-title {
            padding: 15px 0px 15px 0px;
            margin: 0;
            font-size: 20px;
            font-weight: 500;
            display: flex;

            .booking-heading {
                padding-left: 20px;
                font-size: 20px;
            }
            @media (max-width: 767px) {
                display: unset;
            }

            @media (max-width: 567px) {
                padding: 15px 0px 15px 0px;
                font-size: 18px;
            }
            .images-logo {
                height: 70px;
                @media (max-width: 567px) {
                    height: 50px;
                }
            }

            .booking-title-logo {
                font-weight: 700;
                font-size: 32px;
                color: #fff;

                @media (max-width: 567px) {
                    font-weight: 500;
                    font-size: 20px;
                }

                font-weight: 600;
                .heading-logo {
                    color: #fff;
                    font-weight: 700;
                    font-size: 32px;
                }
            }
        }
    }
}

.top-header {
    .btn-primary {
        border-color: transparent !important;
        &:focus {
            color: #fff;
            background-color: transparent;
            border-color: none;
            box-shadow: 0 0 0 0.2rem transparent !important;
        }
    }
}

.background-box-color {
    background-color: rgb(212, 212, 212);
    padding: 20px;
    .background-wrapper {
        background-color: #000;
        padding: 20px 0px;

        .content-waraper {
            padding-left: 0px;
            @media (max-width: 767px) {
                padding: 0px 15px;
            }
        }
        .accept-title {
            .title {
                color: #fff;
                font-size: 18px;
            }
        }
        .sign-box {
            display: flex;
            column-gap: 20px;
            flex-direction: row;
            @media (max-width: 576px) {
                flex-direction: column;
            }
        }
        .print-btn {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.background-box-wrapper {
    padding: 14px;
    .booking-title {
        display: flex;
        justify-content: flex-end;
        font-size: 20px;
        font-weight: 600;

        @media (max-width: 767px) {
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.background-box-color {
    .card {
        background-color: #383636;
        border: none;

        .card-body {
            background-color: #000;
            padding: 0;

            .card-header-tabs-box {
                padding: 0px 10px;
                z-index: 1;
                background: black;
                a {
                    color: #cac7c7;
                    /* border: 1px solid #cbcbcb; */
                    background-color: #383636;
                    margin: 2px 4px;
                    border-color: none;
                    &:hover {
                        border: transparent;
                        border: none;
                        color: #fff;
                    }
                    &.active {
                        color: #fff;
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .nav-item {
                        .nav-link {
                            .active {
                                color: pink;
                            }
                        }
                    }
                }
            }
        }
    }
}

.custom-modal {
    .header-box {
        background-color: #000;
        color: #fff;
        .accept-title {
            font-weight: 500;
        }
    }
    .model-paragraph {
        .body-content {
            font-size: 18px;
            margin: 20px 0px;
            padding: 20px 0px;
            border-bottom: 1px solid grey;
        }
        .modal-label {
            font-size: 18px;
        }
        .agree-btn {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.no-point {
    pointer-events: none;
}

// .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link
