.mini-navbar {
    background-color: rgb(42, 134, 255);
}
.main-wrap-box {
    margin: 0px 50px;
    @media screen and (max-width: 576px) {
        margin: 0;
        padding: 0px 20px;
    }

    .model-box {
        width: 500px;
        margin: 0 auto;
        background-color: #fff;
        box-shadow: -1px 2px 9px #e1e1e1;
        // box-shadow:5px 4px 5px #e1e1e19c;
        border-radius: 4px;
        @media screen and (max-width: 576px) {
            width: 100%;
        }

        .confirm-logo {
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: -1px 2px 9px #e1e1e1;
            padding: 20px 20px;
            border-radius: 4px;
            img {
                width: 33%;
                height: auto;
            }
        }
        .pricelist-heading {
            padding: 20px 40px;
            h5 {
                color: #271a93;
                font-size: 25px;
                font-weight: 800;
                @media screen and (max-width: 576px) {
                    font-size: 16px;
                    text-align: center;
                }
            }
            .title-price {
                font-size: 18px;
                color: #271a93;
                font-weight: 700;
                @media screen and (max-width: 576px) {
                    font-size: 12px;
                }
            }

            .disable-title {
                font-size: 18px;
                color: #271a93;
                margin-top: 60px;
                margin-bottom: 200px;
                text-align: center;
                font-weight: 700;
                @media screen and (max-width: 576px) {
                    font-size: 12px;
                    margin-top: 30px;
                    margin-bottom: 100px;
                }
            }

            .border-line {
                border-bottom: 1px solid rgb(224, 224, 224);
            }

            .payment-method {
                .title {
                    font-size: 16px;
                    font-weight: 500;
                }

                .number {
                    color: #000000bf;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
            .button-download {
                border-bottom: 1px solid rgb(224, 224, 224);
                padding: 30px 0px 28px 0px;
                button {
                    background-color: green;
                    border-radius: 25px;
                    width: 100%;
                    border: none;
                    height: 42px;
                    text-transform: capitalize;
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
}

.left-panel-card {
    margin: 30px 0px;
    background-color: rgb(255, 255, 255);
    box-shadow: -4px 3px 16px 5px #e1e1e1;
    padding: 20px 40px;
    border-radius: 4px;

    @media screen and (max-width: 576px) {
        padding: 20px 20px;
    }

    .heading-first {
        padding: 20px 0px;
        font-size: 40px;
        color: #464545;
        font-weight: 600;
        margin: 0;

        @media screen and (max-width: 991px) {
            font-size: 25px;
        }
    }

    .invoice-title {
        font-size: 32px;
        font-weight: 400;
        color: #8d8d8d;
        @media screen and (max-width: 576px) {
            font-size: 18px;
        }
    }
    .payment-card {
        .label-payment {
            font-size: 20px;
            color: #000;
            font-weight: 400;
        }

        .form-control {
            width: 200px;
            height: 45px;
            border-radius: 4px;

            @media screen and (max-width: 576px) {
                width: 100%;
                height: 40px;
            }
        }
    }

    .name-card-box {
        .form-control {
            height: 45px;
            border-radius: 4px;

            @media screen and (max-width: 576px) {
                height: 40px;
            }
        }
    }

    .card-number {
        .form-control {
            height: 45px;
            border-radius: 4px;
            @media screen and (max-width: 576px) {
                height: 40px;
            }
        }
    }

    .main-title-box {
        column-gap: 20px;
        justify-content: space-between;
        @media screen and (max-width: 991px) {
            flex-direction: column;
        }

        .exp-date-title {
            width: 40%;
            @media screen and (max-width: 991px) {
                width: 100%;
            }

            .form-control {
                height: 45px;
                border-radius: 4px;
                @media screen and (max-width: 991px) {
                    height: 40px;
                }
            }
        }

        .cvv-code {
            width: 50%;
            @media screen and (max-width: 991px) {
                width: 100%;
            }
            .form-control {
                height: 45px;
                border-radius: 4px;
                @media screen and (max-width: 991px) {
                    height: 40px;
                }
            }
        }

        .postal-code {
            width: 100%;
            .form-control {
                height: 45px;
                border-radius: 4px;
                @media screen and (max-width: 991px) {
                    height: 40px;
                }
            }
        }
    }

    .button-box {
        margin: 30px 0px;
        @media screen and (max-width: 576px) {
            margin: 10px 0px;
        }
        button {
            background-color: rgb(19, 197, 19);
            color: #fff;
            border: none;
            padding: 14px 60px;
            border-radius: 20px;
            font-size: 22px;
            @media screen and (max-width: 991px) {
                padding: 12px 20px;

                font-size: 16px;
            }
        }
    }

    .payment-title-decline {
        span {
            color: #7dc347;
            font-size: 18px;
            margin: 10px 0px;
            @media screen and (max-width: 576px) {
                font-size: 14px;
            }
        }
    }

    .comment-paragraph {
        padding: 20px 55px;
        @media screen and (max-width: 991px) {
            padding: 20px 0px;
        }
        p {
            font-size: 22px;
            font-weight: 500;
            color: #8d8d8d;
            text-align: center;

            margin-bottom: 0px;

            @media screen and (max-width: 576px) {
                font-size: 14px;
            }
        }
    }
}

.right-panel-card {
    margin: 30px 0px;

    .right-panel-one {
        background-color: rgb(255, 255, 255);
        box-shadow: -4px 3px 16px 5px #e1e1e1;
        padding: 20px;
        border-radius: 4px;

        .logo-images {
            img {
                width: 100%;
            }
        }

        .sample-box {
            padding: 15px 0px;
            .title {
                font-size: 22px;
                font-weight: 600;
                color: #000;
            }
        }

        .invoice-title {
            .title {
                font-size: 18px;
                color: #000;
                font-weight: 600;
            }
            .number {
                font-size: 18px;
                color: #000;
                font-weight: 600;
            }
        }
    }
    .right-panel-two {
        margin: 10px 0px;
        background-color: rgb(255, 255, 255);
        box-shadow: -4px 3px 16px 5px #e1e1e1;
        padding: 20px;
        border-radius: 4px;

        .invoice-title {
            .title {
                font-size: 18px;
                color: #000;
                font-weight: 600;
            }
            .number {
                font-size: 18px;
                color: #000;
                font-weight: 600;
            }
        }
    }
    .right-panel-three {
        background-color: rgb(255, 255, 255);
        box-shadow: -4px 3px 16px 5px #e1e1e1;
        padding: 20px;
        border-radius: 4px;

        .button-view {
            button {
                background-color: #fff;
                color: #000 !important;
                text-transform: capitalize;
                font-size: 20px;
                border: 1px solid #000;
                padding: 6px 25px;
                font-size: 20px;
                border-radius: 25px;
            }
        }
    }

    .info-title {
        font-size: 14px;
        font-weight: 600;
    }
}
