table.calendar td.chosen {
    background-color: #78b343;
    color: white;
    font-weight: bold;
}
.dispatch-chosen {
    background-color: #78b343;
    padding: 10px 14px;
}

/*Dispatch available day*/
table.calendar td.available {
    background-color: #17a2b8;
    color: white;
    font-weight: bold;
}
.dispatch-available {
    background-color: #17a2b8;
    padding: 10px 14px;
}

.fc-event {
    cursor: pointer;
}

