.forgot-page-wrapper {
    background-color: rgb(199, 199, 199);
    overflow: hidden;

    .forgot-box {
        border-radius: 30px;
        justify-content: space-between;
        // align-items: center;
        padding: 50px;
        column-gap: 20px;
        margin: 40px 40px;
        background-color: rgb(238, 238, 238);
        border: 3px solid #fff;
        height: calc(100vh - 80px);

        @media screen and (max-width: 767px) {
            flex-direction: column;
            padding: 0px;
        }

        .images-wrap {
            display: flex;
            flex: 1;

            @media (max-width: 768px) {
                display: none;
            }
            img {
                width: 100%;
                height: auto;
                border-radius: 30px;
            }
        }

        .login-box-content {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            height: 100%;
            justify-content: center;

            // @media (max-width: 768px) {
            //     display: unset;
            // }
            .link-new {
                color: #000;
                font-size: 16px;
                font-style: italic;
                font-weight: 500;
                a {
                    color: rgb(4, 0, 255);
                    font-size: 16px;
                    font-weight: 500;
                }
            }

            .box-input {
                flex-direction: column;
                display: flex;
                width: 100%;
                max-width: 450px;
                margin: 0 auto;
                h3 {
                    color: #000;
                    font-weight: 700;
                    font-size: 32px;
                    margin: 50px 0px 20px 0px;
                    @media (max-height: 768px) {
                        margin: 5px 0px 5px 0px;
                        font-size: 22px;
                        font-weight: 700;
                    }
                }
                h6 {
                    color: rgb(22, 22, 22);
                    font-size: 15px;
                    width: 85%;
                    text-align: justify;
                    font-weight: 700;
                    margin-bottom: 50px;

                    @media (max-height: 768px) {
                        margin: 5px 0px 20px 0px;
                        font-size: 12px;
                        font-weight: 700;
                    }
                }

                .form-input {
                    .form-group {
                        input {
                            height: 55px;
                            background-color: #fff !important;
                            width: 100%;
                            border-radius: 8px;
                            @media (max-height: 768px) {
                                height: 35px;
                            }
                        }
                    }
                }

                .forget-link {
                    padding: 0px 30px;
                    display: flex;
                    justify-content: flex-end;
                    font-size: 18px;
                    @media (max-height: 768px) {
                        font-size: 12px;
                    }
                    a {
                        color: blue;
                    }
                }
            }
        }
    }

    .sign-up-button {
        padding: 20px 30px;

        @media (max-height: 768px) {
            padding: 10px 30px;
        }

        button {
            height: 55px;
            background-color: rgb(236, 112, 112) !important;
            width: 100%;
            color: #fff;
            border-radius: 8px;
            border: none;

            @media (max-height: 768px) {
                height: 35px;
            }
        }
    }

    .reset-link-button {
        padding: 20px 30px;

        @media (max-height: 768px) {
            padding: 10px 30px;
        }

        button {
            height: 55px;
            background-color: blue !important;
            width: 100%;
            color: #fff;
            border-radius: 8px;
            border: none;

            @media (max-height: 768px) {
                height: 35px;
                font-size: 14px;
            }
        }
        .login {
            text-align: center;
            margin-top: 20px;

            @media (max-height: 768px) {
                margin-top: 10px;
                font-size: 13px;
            }
            a {
                color: blue !important;
                width: 100%;
                font-weight: 700;
            }
        }
    }
}
