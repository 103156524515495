.booking-model {
    .modal-dialog {
        max-width: 60%;
        width: 60%;
    }
}

.payment-form-main {
    padding: 10px 0px;
    height: 100%;
    .payment-form-headding {
        font-size: 18px;
        line-height: 30px;
        font-weight: 700;
        margin: 0px 0;
        color: #fff;

        @media (max-width: 1024px) {
            font-size: 18px;
            font-weight: 500;
        }
    }
    .payment-form-desc-main {
        padding: 15px 30px;
        .top-section {
            .desclaimer-text p {
                color: #fff !important;
                font-size: 18px !important;
                font-weight: 500 !important;
                word-wrap: break-word;
                overflow-wrap: break-word;
            }
            .desclaimer-text font {
                color: #fff !important;
                font-size: 18px !important;
                font-weight: 500 !important;
            }
            .desclaimer-text span {
                color: #fff !important;
                font-size: 18px !important;
                font-weight: 500 !important;
            }
        }
        .bottom-section {
            color: #fff;
        }
        @media (max-width: 1024px) {
            padding: 10px 15px;
        }

        border-radius: 10px;
        height: 100%;
        background-color: #383636;

        // .desclaimer-text {
        //     color: #fff !important;
        //     font-size: 18px;
        //     font-weight: 500;
        // }
        // .desclaimer-text {
        //     color: #fff;
        //     font-size: 18px;
        //     font-weight: 500;
        //     word-wrap: break-word;
        //     overflow-wrap: break-word;
        // }

        .conditon-warpper {
            background-color: #fff;
            padding: 20px 20px;
            overflow-y: auto;
            width: 100%;
            height: 45vh;

            .terms-text {
                color: #000;
                font-size: 18px;
                font-weight: 500;
                word-wrap: break-word;
                overflow-wrap: break-word;
            }
            .terms-text p {
                color: #000;
                font-size: 18px;
                font-weight: 500;
                word-wrap: break-word;
                overflow-wrap: break-word;
            }
            .terms-text span {
                color: #000;
                font-size: 18px;
                font-weight: 500;
                word-wrap: break-word;
                overflow-wrap: break-word;
            }
            .terms-text font {
                color: #000;
                font-size: 18px;
                font-weight: 500;
                word-wrap: break-word;
                overflow-wrap: break-word;
            }
        }
        .initial-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            column-gap: 10px;
            .initial-text {
                color: #fff;
                font-size: 15px;
                font-weight: 400;
            }
            .initial-input {
                max-width: 70px;
            }
        }
    }
}

.scroll-icon {
    overflow-y: scroll;
    scrollbar-color: #6d6d6d white;
    scrollbar-width: 5px;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #ebebeb;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #6d6d6d;
    }
}

.top-header {
    .top-header-box {
        padding: 0px 20px;
        background-color: #000;
        .booking-title {
            padding: 15px 0px 15px 0px;
            margin: 0;
            font-size: 18px;
            font-weight: 500;
            display: flex;

            .booking-heading {
                padding-left: 20px;
                font-size: 20px;
            }
            @media (max-width: 767px) {
                display: unset;
            }

            @media (max-width: 567px) {
                padding: 15px 0px 15px 0px;
                font-size: 18px;
            }
            .images-logo {
                height: 70px;
                @media (max-width: 567px) {
                    height: 50px;
                }
            }

            .booking-title-logo {
                font-weight: 700;
                font-size: 32px;
                color: #fff;

                @media (max-width: 567px) {
                    font-weight: 500;
                    font-size: 16px;
                }

                font-weight: 600;
                .heading-logo {
                    color: #fff;
                    font-weight: 700;
                    font-size: 32px;
                }
            }
        }
        .button-head {
            button {
                padding: 7px 75px;
                border-radius: 25px;
                font-size: 18px;
                font-weight: 500;

                @media (max-width: 767px) {
                    padding: 4px 14px;
                    font-size: 16px;
                    font-weight: 400;
                }
            }

            @media (max-width: 767px) {
                margin-top: -45px;
            }
        }
    }
}

.top-header {
    .btn-primary {
        border-color: transparent !important;
        &:focus {
            background-color: transparent;
            border-color: none;
            box-shadow: 0 0 0 0.2rem transparent !important;
        }
    }
}

.background-box-color {
    background-color: rgb(212, 212, 212);
    padding: 20px;
    .background-wrapper {
        background-color: #000;
        padding: 20px 0px;

        .content-waraper {
            padding-left: 0px;
            @media (max-width: 767px) {
                padding: 0px 15px;
            }
        }

        .btn-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 15px;

            .back-btn {
                button {
                    background-color: #646262;
                    padding: 7px 35px;
                    border: none;
                    font-weight: 600;
                }
            }
            .continue-btn {
                button {
                    background-color: #646262;
                    padding: 7px 35px;
                    border: none;
                    font-weight: 600;
                }
            }
        }
    }
}

.no-point {
    pointer-events: none;
}

// .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link
