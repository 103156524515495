@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800&display=swap');
@import '~tempusdominus-bootstrap/src/sass/tempusdominus-bootstrap-build.scss'; // Or the one above.
$enable-shadows: true !default;
$theme-colors: (
    primary: #000000,
    secondary: #6ab1ab,
    warning: #f2ff25,
    default: #000,
);
@import '~bootstrap/scss/bootstrap';

body,
html,
main {
    height: 100%;
}

/* Fonts */
h1,
h1.display-1,
h2,
h2.display-1,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    &.thin {
        font-weight: 300;
    }
}

h1.page-head {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 3rem;
}
.tr-row {
    &:hover td.td-action i {
        color: #fff;
    }
    td,
    td.td-action {
        font-size: 0.8em;
        i {
            color: #444444;
        }
    }
}

strong,
b,
.bold,
.thick,
.btn,
label {
    font-weight: 700;
}
.regular {
    font-weight: 400;
}

p,
li,
td,
span,
a,
.btn-light {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    &.large {
        font-size: 1.3rem;
    }
    &.x-large {
        font-size: 1.5rem;
    }
}
.footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
}

/* Colors */
.light-gray-bg {
    background-color: #f7f7f7;
}
.dark-gray-bg {
    background-color: #444444 !important;
}

.light-green {
    color: #bbbbbb;
}

.text-muted {
    background-color: #f4faee !important;
}

.green,
label {
    color: #000000;
}

.dark-green {
    color: #444444;
}
.dark-green-bg {
    background-color: #444444;
}

a,
.teal {
    color: #444444;
}

.light-blue {
    color: #ffffff !important;
}
.light-blue-bg {
    background-color: #ffffff !important;
}
.bg-base {
    color: #000 !important;
    background-color: #ffffff;
}
.alt-green {
    color: #000000 !important;
}
.alt-green-bg {
    background-color: #000000;
}

.bg-tier-1 {
    color: #fff !important;
    background-color: #000000;
}
.light-orange {
    color: #f9c50a !important;
}
.light-orange-bg {
    background-color: #f9c50a;
}
.bg-tier-2 {
    color: #000 !important;
    background-color: #f9c50a;
}
.orange {
    color: #f59108 !important;
}
.orange-bg {
    background-color: #f59108;
}
.bg-tier-3 {
    color: #fff !important;
    background-color: #f59108;
}

/* Global */
.center {
    width: 100%;
    text-align: center;
    img {
        margin-left: auto;
        margin-right: auto;
    }
}
.clearfix {
    *zoom: 1;
    &:after {
        clear: both;
        content: '';
        display: table;
    }
}
img {
    color: #fff;
}
.screen-reader-text,
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
}

.btn-primary {
    text-transform: uppercase;
    color: #fff !important;
    font-weight: bold;
}
.loading-modal {
    .modal-dialog {
        .modal-content {
            position: fixed;
            top: 50%;
            left: 50%;
            background-color: transparent;
            box-shadow: none;
            border: none;
        }
    }
    .modal-backdrop {
        background-color: rgba(0, 0, 0, 0.0001) !important;
    }
}

.test {
    display: flex;
    justify-content: space-between;
    background-color: white;
    text-align: center;
}

@include media-breakpoint-up(md) {
    .day {
        height: 12vw;
    }
    .h-md-100 {
        height: 100% !important;
    }
}

@include media-breakpoint-down(md) {
    h1.page-head {
        font-size: 2.5rem;
    }
    h1 {
        font-size: 2rem;
    }
    .center-xs {
        text-align: center !important;
    }
}

@include media-breakpoint-down(sm) {
    h1.page-head {
        font-size: 2.25rem;
    }
    h1 {
        font-size: 1.75rem;
    }
}

@include media-breakpoint-down(xs) {
    h1.page-head {
        font-size: 2rem;
    }
    h1 {
        font-size: 1.5rem;
    }

    .display-4 {
        font-size: 1.5rem;
    }
}

input::placeholder {
    font-style: italic;
}
.form-control {
    color: #292929;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .img-fluid {
        width: 100%;
    }
}

.fullHeight {
    height: 100vh;
}

.sticky-bottom {
    position: sticky;
    bottom: 0;
}

.d-picker-w-100 {
    .react-datepicker-wrapper {
        display: block;
    }
}

.overflow-x-auto {
    overflow-x: auto;
}

.float-right {
    float: right;
}

.table-striped tbody tr:hover,
.table-striped tbody tr:nth-of-type(odd):hover {
    cursor: pointer;
    background-color: #6ab1ab !important;
    color: white;
}

.details-list {
    padding-inline: 10px;
    dd {
        min-height: 24px;
    }
}
.d-picker-w-100 {
    .react-datepicker-wrapper {
        display: block;
        width: 100%;
    }
}
.icon_calender {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #495057;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 4.5px;
}

.test {
    .styles_dateTimePicker__2NFfQ w-100 {
    }
    .dropdown-menu {
        width: 15rem !important;
    }
}
