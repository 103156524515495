.tour-model {
    .modal-dialog {
        max-width: 60%;
        width: 60%;
    }
}
.payment-form-main {
    .payment-form-wrapper {
        .payment-form-headding {
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
            margin: 20px 0;
            color: #fff;
        }
        .payment-form-desc-main {
            padding: 25px 20px;
            border-radius: 10px;
            .payment-form-label {
                font-size: 18px;
                line-height: 28px;
                font-weight: 500;
                color: #fff;
                flex: 0.4;

                @media (max-width: 576px) {
                    font-size: 16px;
                }
            }
            background-color: #383636;
            .payment-form-group {
                .btn-apply {
                    background-color: #fff;
                }

                .payment-form-input-group {
                    flex: 1;
                    row-gap: 5px;
                    .payment-form-input {
                        border-radius: 5px;
                        font-size: 18px;
                        line-height: 28px;
                        font-weight: 400;
                        &:focus {
                            box-shadow: unset;
                        }
                    }
                    .payment-form-select-group {
                        column-gap: 5px;
                        .payment-form-select {
                            border-radius: 5px;
                            font-size: 18px;
                            line-height: 28px;
                            font-weight: 400;
                            &:focus {
                                box-shadow: unset;
                            }
                        }
                    }
                    .payment-form-sub-input {
                        column-gap: 5px;
                        .form-sub-input {
                            border-radius: 5px;
                            font-size: 18px;
                            line-height: 28px;
                            font-weight: 400;
                            min-width: 300px;
                            width: 100%;
                            flex: 1;
                            @media (max-width: 1200px) {
                                min-width: 100%;
                            }
                            &:focus {
                                box-shadow: unset;
                            }
                        }
                        .form-cvv-input {
                            border-radius: 5px;
                            font-size: 18px;
                            line-height: 28px;
                            font-weight: 400;
                            min-width: 200px;
                            width: 100%;
                            flex: 0.3;
                            @media (max-width: 1200px) {
                                min-width: 100%;
                            }
                            &:focus {
                                box-shadow: unset;
                            }
                        }
                    }
                }
                .payment-form-disscount {
                    flex: 1;
                    flex-direction: row;
                    column-gap: 5px;
                }
            }
        }
    }
}
.tour-details-main {
    .tour-details-heading {
        font-size: 28px;
        line-height: 30px;
        font-weight: 600;
        margin: 20px 0;
        color: #fff;
    }
    .tour-details-wrapper {
        border-radius: 10px;
        padding: 25px 20px;
        background-color: #261e1e;
        .tour-wrap {
            border-bottom: 1px solid #fff;
            margin-bottom: 15px;
            .tour-desc {
                font-size: 18px;
                line-height: 28px;
                font-weight: 600;
                color: #fff;
                text-transform: uppercase;
                margin-bottom: 0;
            }
            .tour-details {
                margin-bottom: 15px;
                .tour-qty {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 600;
                    color: #fff;
                    margin-bottom: 0;
                }
                .tour-price {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 700;
                    color: #fff;
                    margin-bottom: 0;
                }
            }
        }
        .tour-total {
            .total-wrapper {
                margin-bottom: 5px;
                .total-text {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 700;
                    color: #fff;
                }
                .total-amt {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }
    }
}

.background-wrapper {
    background-color: #000;
    border-radius: 12px;
    .tour-deatils-box {
        background-color: #261e1e;
        margin-top: 10px;
        border-radius: 10px;
        padding: 25px 20px;

        .deposite-text-title {
            display: flex;
            flex-direction: column;
            .deposite-text {
                font-weight: 600;
                font-size: 16px;
            }
            .deposite-date-title {
                font-size: 12px;
            }
        }

        .balance-text {
            font-weight: 600;
            font-size: 16px;
            color: #fff;
        }
        .balance-radio label {
            color: #fff;
        }

        .form-text-box {
            font-size: 20px;
            color: #fff;
        }

        .form-text-first {
            font-size: 16px;
            font-weight: 600;
        }

        .form-leftside {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
        }
        .radio-button-form {
            display: flex;
            align-items: flex-end;
            height: 100%;
            margin: 0px;
        }

        .radio-button-form {
            padding-left: 10px;
        }
        .radio-button-form .form-control {
            width: 100%;
            height: 30px;

            @media (max-width: 1276px) {
                min-width: 100%;
            }
        }
    }

    .button-wrapper {
        .button-of-first {
            .btn-primary {
                background-color: #fff;
                color: #fff;
                width: 100%;
                padding: 5px;
                &:focus {
                    box-shadow: none;
                }
                &:not(:disabled) {
                    &:not(.disabled) {
                        &:active {
                            background-color: #fff;
                            border-color: #fff;
                            box-shadow: unset;
                        }
                    }
                    &:not(.disabled).active {
                        background-color: #fff;
                        border-color: #fff;
                    }
                }
            }
        }
        .button-of-secound {
            .btn-primary {
                background-color: green;
                color: #fff;
                width: 100%;
                border: none;
                margin-top: 10px;

                &:focus {
                    box-shadow: none;
                }
                &:not(:disabled) {
                    &:not(.disabled) {
                        &:active {
                            background-color: green;
                            border-color: green;
                            box-shadow: unset;
                        }
                    }
                    &:not(.disabled).active {
                        color: #fff;
                        background-color: green;
                        border-color: green;
                    }
                }
            }
        }
    }
}

.top-header {
    .top-header-box {
        padding: 0px 20px;
        background-color: #000;
        .tour-title {
            padding: 15px 0px 15px 0px;
            margin: 0;
            font-size: 36px;
            font-weight: 500;
            display: flex;

            @media (max-width: 767px) {
                font-size: 14px;
            }

            .tour-heading {
                padding-left: 20px;
            }
            @media (max-width: 767px) {
                display: unset;
            }

            @media (max-width: 567px) {
                padding: 15px 0px 15px 0px;
                font-size: 18px;
            }
            .images-logo {
                height: 70px;
                @media (max-width: 567px) {
                    height: 50px;
                }
            }

            .tour-title-logo {
                font-weight: 700;
                font-size: 32px;
                color: #fff;

                @media (max-width: 567px) {
                    font-weight: 500;
                    font-size: 20px;
                }

                font-weight: 600;
                .heading-logo {
                    color: #f57647;
                    font-weight: 700;
                    font-size: 32px;
                }
            }
        }

        .button-head {
            button {
                padding: 7px 75px;
                border-radius: 25px;
                font-size: 18px;
                font-weight: 500;

                @media (max-width: 767px) {
                    padding: 4px 14px;
                    font-size: 12px;
                    font-weight: 400;
                }
            }

            @media (max-width: 767px) {
                margin-top: -45px;
            }
        }
    }
}

.top-header {
    .btn-primary {
        border-color: transparent !important;
        &:focus {
            background-color: transparent;
            border-color: none;
            box-shadow: 0 0 0 0.2rem transparent !important;
        }
    }
}

.background-box-color {
    background-color: rgb(212, 212, 212);
}

.background-box-wrapper {
    padding: 14px;
    .tour-title {
        display: flex;
        justify-content: flex-end;
        font-size: 36px;
        font-weight: 600;
    }
    .inner-box {
        max-width: 800px;
        border-radius: 13px;
        margin: 0 auto;
        .images-logo {
            padding: 20px 0px;
            img {
                height: title-processed;
                width: 20%;
            }
        }

        .card-payment-box {
            padding: 13px;
            background-color: #c30e2e;
            color: #fff;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .payment-title {
                font-size: 20px;
                font-weight: 600;

                @media (max-width: 767px) {
                    font-size: 16px;
                }
            }
        }
        .title-processed {
            color: #c30e2e;

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
        .title-amount {
            color: #c30e2e;
            font-size: 22px;
            font-weight: 700;

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }
}

.tour-selected {
    border: 0.5px solid;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgb(52, 52, 52);
}

.no-point {
    pointer-events: none;
}

.btn-responsive {
    width: 100%;
    padding: 8px 16px;
}

@media (min-width: 768px) {
    .btn-responsive {
        width: 50%;
    }
}

@media (min-width: 992px) {
    .btn-responsive {
        width: 30%;
    }
}
