.test {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    text-align: center;
    max-width: fit-content;
    .rc-time-picker-input {
        height: 38px;
    }
}

.icon_calender {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #495057;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 4.5px;
}

.error {
    color: red;
}

// Invoice Print
.img-box {
    border: 1px solid #c1c1c1;
    width: 100%;
    img {
        width: 100%;
        object-fit: cover;
    }
}

.main-table {
    width: 450px;
    margin: 15px 0;
    tr {
        line-height: 35px;
        td[colspan='2'] {
            width: 230px;
        }
    }
}

@media print {
    .table .thead-dark th {
        color: #fff !important;
        background-color: #343a40 !important;
        border-color: #454d55 !important;
    }
}

.page {
    background-color: whitesmoke;
    min-height: 98vh;
    padding: 2vh;
    page-break-before: unset;
    inset: 0;
}
@media print {
    html,
    body {
        border: 1px solid white;
        height: 99%;
        page-break-after: avoid;
        page-break-before: avoid;
    }
}

.height-95vh {
    height: 95vh;
}
@media screen {
    .screen-d-none {
        display: none;
    }
}
@media print {
    .bg-print-gray {
        background-color: #c1c1c1 !important;
        -webkit-print-color-adjust: exact;
    }
}
// @media print { html, body { height:100vh; margin: 0 !important; padding: 0 !important; overflow: hidden; } }
