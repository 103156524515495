.hidden {
    display: none;
}

.sg-card-lt {
    display: block;
    font-size: 16px;
    font-weight: bold;
}

.dtail {
    font-size: 17px;
    display: block;
    font-weight: 500;
}

.dtail-bold {
    font-size: 19px;
    font-weight: bold;
    display: block;
}

.head-div {
    color: #fff;
}

.sg-card-lt {
    display: block;
    font-size: 16px;
    font-weight: bold;
}

.sg-card-rt {
    display: block;
    font-size: 16px;
    font-weight: bold;
}
.sg {
    display: block;
    font-size: 14px;
}
.bg-work-ticket {
    background-color: blue !important;
}

.oe-list:nth-child(odd) {
    background: #e2e2e2;
}

.oe-list:nth-child(even) {
    background: transparent;
}

.item-col {
    padding-left: 5px !important;
    padding-right: 5px !important;
    text-align: center !important;
}

.content-section {
    padding-bottom: 66px;
}
